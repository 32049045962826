// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-topics-jsx": () => import("./../../../src/pages/topics.jsx" /* webpackChunkName: "component---src-pages-topics-jsx" */),
  "component---src-templates-answer-jsx": () => import("./../../../src/templates/answer.jsx" /* webpackChunkName: "component---src-templates-answer-jsx" */),
  "component---src-templates-pagination-answers-jsx": () => import("./../../../src/templates/pagination/answers.jsx" /* webpackChunkName: "component---src-templates-pagination-answers-jsx" */)
}

