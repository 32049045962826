import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  typography: {
    h1: {
      fontSize: `${40 / 16}rem`,
    },
    h2: {
      fontSize: `${34 / 16}rem`,
    },
    h3: {
      fontSize: `${28 / 16}rem`,
    },
    h4: {
      fontSize: `${24 / 16}rem`,
    },
    h5: {
      fontSize: `${18 / 16}rem`,
    },
    h6: {
      fontSize: `${16 / 16}rem`,
    },
    subtitle1: {
      fontSize: `${18 / 16}rem`,
    },
    subtitle2: {
      fontSize: `${18 / 16}rem`,
    },
    body1: {
      fontSize: `${18 / 16}rem`,
    },
    body2: {
      fontSize: `${16 / 16}rem`,
    },
    button: {
      fontSize: `${18 / 16}rem`,
    },
  },
});
